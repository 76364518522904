import React, { useCallback, useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";

import { client } from "api";
import { DataTable } from "components/tables/DataTable";

const getPatients = async () => {
  const { data } = await client.get("patients");
  return data.patients;
};

export const PatientsList = () => {
  const [patients, setPatients] = useState(null);
  // const history = useHistory();

  useEffect(() => {
    getPatients().then(setPatients);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterFunction = useCallback((patient, filter) => {
    const lcFilter = filter.toLowerCase();
    return (
      patient.id.toString().toLowerCase().includes(lcFilter) ||
      patient.email?.toLowerCase()?.includes(lcFilter) ||
      patient.first_name?.toLowerCase()?.includes(lcFilter) ||
      patient.last_name?.toLowerCase()?.includes(lcFilter) ||
      patient.vertical_list?.toLowerCase()?.includes(lcFilter) ||
      patient.waitlist_facilities?.toLowerCase()?.includes(lcFilter) ||
      patient.created_at?.toLowerCase()?.includes(lcFilter)
    );
  }, []);

  // const handleRowClicked = useCallback(
  //   (patient) => {
  //     history.push(`/patients/${patient.id}`);
  //   },
  //   [history]
  // );

  if (!patients) {
    return <div>Loading...</div>;
  }

  return (
    <DataTable
      title="Patients"
      data={patients}
      columns={[
        { name: "id", selector: (row) => row.id, sortable: true },
        { name: "email", selector: (row) => row.email },
        { name: "first name", selector: (row) => row.first_name },
        { name: "last name", selector: (row) => row.last_name },
        {
          name: "created at",
          selector: (row) => row.created_at,
          sortable: true,
        },
      ]}
      filterFunction={filterFunction}
      // onRowClicked={handleRowClicked}
    />
  );
};

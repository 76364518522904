import React, { useCallback, useState } from "react";
import { Switch, Route } from "react-router-dom";

import { LoginPage } from "pages/auth/LoginPage";
import { BundlesPage, EditBundlePage } from "pages/bundles";
import {
  FacilityBundlesPage,
  EditFacilityBundlePage,
} from "pages/facilityBundles";
import {
  AddSponsoredListingPage,
  EditFacilityPage,
  EditSponsoredListingPage,
  SponsoredListingsPage,
} from "pages/facilities";
import {
  AddFacilityFeatureOverridePage,
  EditFacilityFeatureOverridePage,
  FacilityFeatureOverridesPage,
} from "pages/facilityFeatureOverrides";
import { AddFaqPage, EditFaqPage } from "pages/faqs";
import {
  AddInsuranceProviderPage,
  EditInsuranceProviderPage,
  InsuranceProvidersPage,
} from "pages/insuranceProviders";
import { PatientsListPage } from "pages/patients";
import {
  AddPaymentTypePage,
  EditPaymentTypePage,
  PaymentTypesPage,
} from "pages/paymentTypes";
import {
  AddPromotionPage,
  EditPromotionPage,
  PromotionsPage,
} from "pages/promotions";
import { AddServicePage, EditServicePage, ServicesPage } from "pages/services";
import {
  AddVerticalContentPage,
  EditVerticalContentPage,
  VerticalContentPage,
} from "pages/verticalContent";
import { AddSymptomPage, EditSymptomPage } from "pages/symptoms";
import { CrudRoutes } from "components/crud/CrudRoutes";
import { AddFacilityPage } from "pages/facilities/AddFacilityPage";
import { FacilityAuditPage } from "pages/facilities/FacilityAuditPage";

export const Routes = () => {
  const [_render, setRender] = useState();
  const authToken = localStorage.getItem("authToken");
  const handleLogin = useCallback(() => {
    setRender((r) => r + 1);
  }, []);
  return authToken ? (
    <Switch>
      <Route path="/bundles" exact component={BundlesPage} />
      <Route path="/bundles/:bundleId" exact component={EditBundlePage} />
      <Route path="/facilityBundles" exact component={FacilityBundlesPage} />
      <Route
        path="/facilityBundles/:facilityId"
        exact
        component={EditFacilityBundlePage}
      />
      <Route path="/clinics/create" exact component={AddFacilityPage} />
      <Route path="/clinics/:facilityId" exact component={EditFacilityPage} />
      <Route
        path="/clinics/all/:facilityId"
        exact
        component={EditFacilityPage}
      />
      <Route
        path="/facilityFeatureOverrides"
        exact
        component={FacilityFeatureOverridesPage}
      />
      <Route
        path="/facilityFeatureOverrides/add"
        exact
        component={AddFacilityFeatureOverridePage}
      />
      <Route
        path="/facilityFeatureOverrides/:facilityId/:featureId"
        component={EditFacilityFeatureOverridePage}
      />
      <Route path="/faqs/add" exact component={AddFaqPage} />
      <Route path="/faqs/:faqId" exact component={EditFaqPage} />
      <Route path="/faqs/:duplicate/:faqId" exact component={EditFaqPage} />
      <Route
        path="/insuranceProviders"
        exact
        component={InsuranceProvidersPage}
      />
      <Route
        path="/insuranceProviders/add"
        exact
        component={AddInsuranceProviderPage}
      />
      <Route
        path="/insuranceProviders/:insuranceProviderId"
        component={EditInsuranceProviderPage}
      />
      <Route path="/patients" exact component={PatientsListPage} />
      <Route path="/paymentTypes" exact component={PaymentTypesPage} />
      <Route path="/paymentTypes/add" exact component={AddPaymentTypePage} />
      <Route
        path="/paymentTypes/:paymentTypeId"
        component={EditPaymentTypePage}
      />
      <Route path="/promotions" exact component={PromotionsPage} />
      <Route path="/promotions/add" exact component={AddPromotionPage} />
      <Route path="/promotions/:promotionId" component={EditPromotionPage} />
      <Route path="/services" exact component={ServicesPage} />
      <Route path="/services/add" exact component={AddServicePage} />
      <Route path="/services/:serviceId" component={EditServicePage} />
      <Route path="/verticalContent" exact component={VerticalContentPage} />
      <Route
        path="/verticalContent/add"
        exact
        component={AddVerticalContentPage}
      />
      <Route
        path="/verticalContent/:verticalContentId"
        exact
        component={EditVerticalContentPage}
      />
      <Route
        path="/verticalContent/:duplicate/:verticalContentId"
        exact
        component={EditVerticalContentPage}
      />
      <Route
        path="/sponsoredListings"
        exact
        component={SponsoredListingsPage}
      />
      <Route
        path="/sponsoredListings/add"
        exact
        component={AddSponsoredListingPage}
      />
      <Route
        path="/sponsoredListings/:sponsoredListingId"
        component={EditSponsoredListingPage}
      />
      <Route path="/facilityAudit" exact component={FacilityAuditPage} />
      <Route path="/table/symptoms/add" exact component={AddSymptomPage} />
      <Route path="/table/symptoms/:symptomId" component={EditSymptomPage} />
      <CrudRoutes />
    </Switch>
  ) : (
    <LoginPage onLogin={handleLogin} />
  );
};

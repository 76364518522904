import React from "react";

import { Page } from "pages/Page";
import { Column } from "components/layout";
import { PatientsList } from "components/patients/PatientsList";

console.log("PatientsList:", PatientsList);

export const PatientsListPage = () => {

  return (
    <Page>
      <Column>
        <PatientsList  />
      </Column>
    </Page>
  );
};
